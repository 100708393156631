import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function ActividadesPage () {
  return (
    <Layout pageInfo={{ pageName: "Actividades" }}>
    <SEO title="Actividades" />
    <br />
    <br />
    <h1>Actividades</h1>
    <br></br>
  </Layout>
  )
}